const Ellipsis = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="6"
      viewBox="0 0 30 6"
      fill="none"
      {...props}
    >
      <circle cx="3" cy="3" r="3" fill="currentColor" />
      <circle cx="15" cy="3" r="3" fill="currentColor" />
      <circle cx="27" cy="3" r="3" fill="currentColor" />
    </svg>
  )
}

export default Ellipsis
