import { Picture, Link } from '@components/ui'
import s from '../../Home.module.css'
import style from './index.module.css'
import cn from 'classnames'
import Arrow from '../Arrow'
import { refLink } from '../../utils'
import Ellipsis from '@components/icons/home/Ellipsis'
import { useEffect, useMemo, useRef, useState } from 'react'
import Slide from '../Slide'

const gap = 6

// 优化代码，减少复杂度
const Icons = ({ icons, width, avatarSize, offset, i }) => {
  const isActive = (offsetLeft) =>
    offsetLeft <= 0 && offsetLeft >= -avatarSize && i === 0 && offset !== 0

  return (
    <div className="flex" style={{ width }}>
      {icons.map((icon, index) => {
        const left = (index + 1) * -gap
        const offsetLeft = offset + avatarSize * index + left
        return (
          <Picture
            source={icon}
            key={index}
            className={cn(
              'relative w-10 shrink-0 min-l:w-[50px] min-xxl:w-[65px]',
              style.avatar,
              { [style.active]: isActive(offsetLeft) }
            )}
            style={{ left: `${left}px` }}
          />
        )
      })}
    </div>
  )
}

/**
 * Renders the Benefits component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data.
 * @param {string} props.locale - The locale string.
 * @param {Object} props.shopCommon - The shop common data.
 * @param {Array} props.relatedProducts - The related products data.
 * @returns {JSX.Element} The rendered Benefits component.
 */
const Benefits = ({ section, shopCommon, relatedProducts }) => {
  const { title, card, lists } = section
  const [offset, setOffset] = useState(0)
  const [avatarSize, setAvatarSize] = useState(40)
  const requestRef = useRef(null)

  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth < 1024) {
      setAvatarSize(40)
    } else if (offsetWidth < 1920) {
      setAvatarSize(50)
    } else {
      setAvatarSize(65)
    }
  }
  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const width = useMemo(() => {
    return card?.icons.length * avatarSize - card?.icons.length * gap
  }, [avatarSize, card?.icons.length])

  useEffect(() => {
    const animation = () => {
      setOffset((prev) => {
        if (prev <= -width) {
          return 0
        }
        return prev - 0.4
      })
      requestRef.current = requestAnimationFrame(animation)
    }
    requestRef.current = requestAnimationFrame(animation)
    return () => {
      cancelAnimationFrame(requestRef.current)
    }
  }, [width])

  return (
    <div className="layer overflow-hidden">
      <div className="content">
        <div className="py-5 min-l:py-10 min-xl:py-[60px]">
          <div className="min-l:text-center">
            <h2
              className={cn(s.title)}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
            {/* {section.view_more && (
              <Link
                href={refLink(section.view_more_link, 'member_view', 'more')}
                className="hidden items-center justify-center gap-[2px] text-center font-semibold leading-[1.2] text-brand-color min-l:flex"
              >
                <span>{section.view_more}</span>
                <Arrow />
              </Link>
            )} */}
          </div>
          <div className="mt-6 min-l:mt-8 min-l:grid min-l:grid-cols-24 min-l:grid-rows-2 min-l:gap-2 min-xl:mt-12">
            {card && (
              <div
                className={cn(
                  'relative overflow-hidden rounded-xl bg-[#e4f0f6] p-4 min-l:order-1 min-xl:p-6',
                  {
                    'min-l:col-span-7': card['col-span'] === 7,
                    'min-l:col-span-14': card['col-span'] === 14,
                  }
                )}
              >
                <p
                  className="text-[40px] font-semibold text-[#042637] min-l:text-[32px] min-xl:text-[64px] min-xxl:text-[96px]"
                  dangerouslySetInnerHTML={{ __html: card.text1 }}
                ></p>
                <p
                  className="text-sm font-medium text-black opacity-75 min-l:text-xs min-xl:text-[16px] min-xxl:text-[20px]"
                  dangerouslySetInnerHTML={{ __html: card.text2 }}
                ></p>
                <div className="mt-6 flex items-center gap-3 min-md:mt-[52px] min-l:absolute min-l:bottom-4 min-l:left-4 min-l:box-border min-l:w-full min-xl:bottom-6 min-xl:left-6 min-xxl:bottom-8 min-xxl:left-8">
                  <div className="w-[177px] overflow-hidden rounded-full min-l:w-[214px] min-xxl:w-[289px]">
                    {card.icons && (
                      <div
                        className="flex"
                        style={{
                          transform: `translateX(${offset}px)`,
                        }}
                      >
                        <Icons
                          icons={card.icons}
                          width={width}
                          avatarSize={avatarSize}
                          offset={offset}
                          i={0}
                        />
                        <Icons
                          icons={card.icons}
                          width={width}
                          avatarSize={avatarSize}
                          offset={offset}
                          index={1}
                        />
                      </div>
                    )}
                  </div>
                  <Ellipsis className="text-[#042637]" />
                </div>
              </div>
            )}
            {lists?.map((item, index) => (
              <div
                key={index}
                className={cn(
                  s.picBox,
                  'relative hidden overflow-hidden rounded-xl min-l:block',
                  {
                    'min-l:col-span-7': item['col-span'] === 7,
                    'min-l:col-span-10': item['col-span'] === 10,
                    'min-l:col-span-14': item['col-span'] === 14,
                    'min-l:row-span-2': item['row-span'] === 2,
                  }
                )}
              >
                <Link
                  href={refLink(
                    item.learn_more,
                    section.title,
                    'learn_' + (index + 1)
                  )}
                >
                  <Picture
                    source={`${item.image}, ${item.image_mob} 769`}
                    className="h-full"
                    imgClassName="object-cover w-full h-full"
                  />
                </Link>
                <div
                  className="absolute bottom-0 left-0 w-full p-4 text-white min-xl:p-6"
                  style={{ color: item.textColor || section.textColor }}
                >
                  <h4
                    className="mb-[6px] font-semibold !leading-[1.2] min-xl:mb-2 min-xl:text-[20px] min-xxl:mb-3 min-xxl:text-2xl"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  ></h4>
                  {item.learn_more ? (
                    <Link
                      className={cn(
                        'span-7 flex items-center gap-[2px] text-xs font-semibold !leading-[1] min-xl:text-base'
                      )}
                      href={refLink(
                        item.learn_more,
                        section.title,
                        'learn_' + (index + 1)
                      )}
                    >
                      <span>
                        {item.learn_more_text ||
                          section.learn_more ||
                          shopCommon.learn_more}
                      </span>
                      <Arrow />
                    </Link>
                  ) : (
                    <span className="font-semibold">
                      {item.learn_more_text}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="min-l:hidden">
            <Slide
              section={section}
              shopCommon={shopCommon}
              relatedProducts={relatedProducts}
              className="min-l:hidden"
              paddingClassName="!p-0 !pt-2"
            ></Slide>
          </div>
          {/* {section.view_more && (
            <div className="mt-9 min-l:hidden">
              <Link
                href={refLink(section.view_more_link, 'member_view', 'more')}
                className="flex items-center justify-center gap-[2px] font-semibold leading-[1.2] text-brand-color"
              >
                <span>{section.view_more}</span>
                <Arrow />
              </Link>
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default Benefits
